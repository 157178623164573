import "../sass/styles.scss";
const sticky = 45;
function init() {
  const mobileMenuButton = document.querySelector('.menu-toggle');
  const sectionSwitcher = document.querySelectorAll('.menu-link');
  const urlParams = new URLSearchParams(window.location.search);
  const ab_test = urlParams.get('exp');
  const swiper = new Swiper('.swiper-container', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });

  const resolveData = new Promise(function (resolve, reject) {
    $.getJSON('https://boards-api.greenhouse.io/v1/boards/nextrollsales/jobs?content=true', function (data) {
      resolve(data);
    });
  });
  resolveData.then(function (data) {
    const jobs = data.jobs;
    buildJobListings(jobs);
  });

  const buildJobListings = (listings) => {
    let job_listing = "";
    const jobsContainer = document.querySelector("#open-positions__wrapper");
    listings.map((job) => {
      job_listing += `
      <div class="open-job-position">
        <h5 class="headline headline__4">${job.title}</h5>
        <a href="${job.absolute_url}" class="button button--outline" target="_blank">Apply Now</a>
      </div>`;
    });
    jobsContainer.innerHTML = job_listing;
  }
  mobileMenuButton.addEventListener("click", (event) => {
    event.preventDefault();
    document.body.classList.toggle("open-nav");
  });
  sectionSwitcher.forEach( (link) => {
    link.addEventListener("click", (event) => {
      const goTo = event.target.hash.split("/");
      scrollTo(goTo[1]);
    });
  });
  if(window.location.hash){
    const path = window.location.hash.split('/');
    scrollTo(path[1]);
  }
  if(ab_test){
    const cta = document.querySelector("#target-cta");
    cta.innerHTML = '<a href="https://boards.greenhouse.io/nextrollsales/jobs/5143516002?gh_src=2a6127392us" class="button button--outline-alt" target="_blank">Find Your Path to Success <i class="fas fa-long-arrow-right"></i></a>';
  }
}
function scrollTo(id) {
  const yOffset = -100; 
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: 'smooth'});
}
document.addEventListener("DOMContentLoaded", init);
window.addEventListener("scroll", () => {
    if (window.pageYOffset > sticky) {
        document.body.classList.add("sticky");
      } else {
        document.body.classList.remove("sticky");
      }
});
